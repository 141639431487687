.station-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}

.station-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}

.station-empty-normal .station-empty-image {
  height: 40px;
}

.station-empty-image {
  height: 100px;
  margin-bottom: 8px;
}

.station-empty-image svg {
  height: 100%;
  margin: auto;
}

.station-empty-img-simple-ellipse {
  fill: #f5f5f5;
}

.station-empty-img-simple-g {
  stroke: #d9d9d9;
}

.station-empty-img-simple-path {
  fill: #fafafa;
}
