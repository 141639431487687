.select-menu {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.7px;
    color: #455560;
    font-family: 'Rubik', sans-serif;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background: transparent;
    border: 1px solid #BFC5D2;
    border-radius: 5px;
    width: 100%;
    height: 48px;
    // overflow: hidden;
    transition: 0.3s;
    display: block;
    position: relative;

    &.active {
        background-color: #e8e9f7;
    }
    
    &.inactive {
        background-color: #FAFAFA;
    }

    .label {
        padding: 6px 5px 6px 12px;
        width: 100%;
        height: 100%;
        background: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        cursor: pointer;

        .selected-item {
            text-align: start;
        }

        .icon {
            width: 22px;
            height: 22px;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .list-item {
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        width: 100%;
        height: fit-content;
        background: white;
        font-size: 13px;
        box-shadow: rgba(0,0,0,0.12) 0px 0px 1px 0px, rgba(0,0,0,0.09) 0px 3px 5px 1px, rgba(0,0,0,0.01) 0px 3px 4px 0px;
        border-radius: 5px;
        max-height: 160px;
        overflow-x: hidden;
        overflow-y: overlay;

        &.visible {
            display: block;
        }

        &.hidden {
            display: none;
        }

        .one-item {
            padding: 6px 12px;
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            font-size: 13px;

            &:hover {
                background-color: #e8e9f7;
            }
        }
    }
}